import { required, maxLength, } from "vuelidate/lib/validators";
import {  especiales,decimalEspecial,requiredSelect} from '@/_validations/validacionEspeciales';

export default () => {
    return {
        Equivale: { required,decimalEspecial },
        Origen : {required,requiredSelect},
        Destino: {required,requiredSelect},
        Status: {},
        tipoDestino: {required},
        tipoOrigen: {required},
        Operator: {},
    }
}