<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :closeOnBackdrop="false"
      color="dark"
      size="lg"
      :title="tituloModal"
      :show.sync="AddModal"
    >
      <CRow>
        <CCol sm="12" lg="7" xl="7">
          <CRow>
            <CCol sm="11" lg="12" xl="12">                                   
              <CSelect
                :placeholder="$t('label.select')"
                :options="itemsUnid"
                addLabelClasses="text-right"
                :label="$t('label.convertionunitType')"
                :horizontal="{label:'col-sm-11 col-lg-6 col-xl-6', input:'col-sm-11 col-lg-6 col-xl-6'}"
                v-model="$v.tipoOrigen.$model"
                :value.sync="tipoOrigen"
                :is-valid="hasError($v.tipoOrigen)" 
                :invalid-feedback="errorMessage($v.tipoOrigen)"
                @change="getTipoOrigen()"
              />                
            </CCol>
             <CCol sm="11" lg="12" xl="12">      
              <CSelect
                addLabelClasses="required text-right"
                :label="$t('label.unitOfOrigin')"
                :horizontal="{label:'col-sm-11 col-lg-6 col-xl-6', input:'col-sm-11 col-lg-6 col-xl-6'}"
                :disabled="disabledInput(itemsOrigen)"
                v-model="Origen"
                :is-valid="hasError($v.Origen)" 
                :invalid-feedback="errorMessage($v.Origen)"
                :value.sync="Origen"
                :options="itemsOrigen"
                :placeholder="$t('label.select')"
                @change="getDestinationOptions($event)"
              />          
            </CCol>
             
            <CCol sm="11" lg="12" xl="12" v-if="this.showDestinationType">                     
              <CSelect                            
                :label="$t('label.typeOfUnitToConvert')"
                addLabelClasses="text-right"
                :placeholder="$t('label.select')"
                :horizontal="{label:'col-sm-11 col-lg-6 col-xl-6', input:'col-sm-11 col-lg-6 col-xl-6'}"
                :options="itemsUnid2"
                v-model="tipoDestino"
                :value.sync="tipoDestino"
                :is-valid="hasError($v.tipoDestino)" 
                :invalid-feedback="errorMessage($v.tipoDestino)"
              />                  
            </CCol>
            <CCol sm="11" lg="12" xl="12" >
              <CSelect
                addLabelClasses="required text-right"
                v-model="Destino"
                :horizontal="{label:'col-sm-11 col-lg-6 col-xl-6', input:'col-sm-11 col-lg-6 col-xl-6'}"
                :disabled="disabledInput(segundoSelect)"
                :value.sync="Destino"
                :placeholder="$t('label.select')"
                :label="$t('label.unitOfDestination')"
                :options="segundoSelect"
                :is-valid="hasError($v.Destino)" 
                :invalid-feedback="errorMessage($v.Destino)"
              />
            </CCol>
            <!-- SI ES UN UPDATE -->
            <CCol sm="11" lg="12" xl="12" v-if="actualizar">
              <CSelect
                :label="$t('label.status')"
                v-model="Status"
                :horizontal="{label:'col-sm-11 col-lg-6 col-xl-6', input:'col-sm-11 col-lg-6 col-xl-6'}" 
                :value.sync="Status"
                :options="selectOptions"
                :is-valid="Status" 
                addLabelClasses="required text-right"
              />
            </CCol>
          </CRow>
        </CCol>
          <!--segunda fila-->
        <CCol sm="12" lg="5" xl="5">
          <CRow class="mr-2">
            <CCol sm="11" lg="12" xl="12" >
              <CInput
                type="number"
                addLabelClasses="required text-right"
                :label="$t('label.equals')"
                :horizontal="{label:'col-sm-11 col-lg-4 col-xl-4', input:'col-sm-11  col-lg-8 col-xl-8'}"
                invalid-feedback="Campo requerido {0,00}"
                v-model="$v.Equivale.$model"
                :is-valid="hasError($v.Equivale)"
              />
      
            
            </CCol>                            
            <CCol sm="11" lg="12" xl="12"  >
              <CSelect
                :label="$t('label.mathematicalOperation')"
                :placeholder="$t('label.select')"
                addLabelClasses="text-right"
                :horizontal="{label:'col-sm-11 col-lg-4 col-xl-4', input:'col-sm-11  col-lg-8 col-xl-8'}"
                :options="selectOperacion"
                v-model="Operator"
                :value.sync="Operator"
                :is-valid="hasError($v.Operator)"
                :invalid-feedback="errorMessage($v.Operator)"
              />
            </CCol>
            <CCol sm="11" lg="12" xl="12" >
              
              <CInput
                label="FORMULA"
                :horizontal="{label:'col-sm-11 col-lg-4 col-xl-4', input:'col-sm-11  col-lg-8 col-xl-8'}"
                :placeholder="
                  '= VALOR' + this.Operator + this.Equivale
                "
                addLabelClasses="text-right"
                disabled
              />
            </CCol>
            
          </CRow>
        </CCol>             
      </CRow>
      <template #footer>
        <CButton color="add" @click="evaluaStatus">
          <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe" @click="AddModal = false">
          <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
  import General from "@/_mixins/general";
  import UpperCase from "@/_validations/uppercase-directive";
  import ConversionVal from "@/_validations/conversion/conversionVal";
  import ModalMixin from '@/_mixins/modal';

  //DATA
  function data() {
    return {
      // VARIABLES
      AddModal: false,
      Loading: false,
      tituloModal: "",
      actualizar: false,
      Status: true,
      FgActConversion:true,
      itemsUnid: [],
      itemsUnid2: [],
      itemsOrigen: [],
      itemsDestino: [],
      val2: "",
      val: "",
      Destino: "",
      Origen: "",
      tipoDestino: "",
      tipoOrigen: "",
      labelEquivale: "EQUIVALE",
      //MODELO
      Equivale: "",
      UnitMeasureFromId: "",
      UnitMeasureToId: "",
      Operator: "*",
      Formula: "",
      Valores: "",
      tipoOrigen2: "",
      showDestinationType: false,
    };
  }

  //COMPUTED
  function segundoSelect() {
    return this.itemsDestino.filter((item) => item.value != this.val);
  }

  function disabledInput(arr) {
    return Array.isArray(arr) && arr.length == 0;
  }

  function isDisabled() {
    return this.$v.$invalid;
  }


  //METHODOS


  function refreshComponent() {
    this.UnitMeasureFromId = "";
    this.UnitMeasureToId = "";
    this.Operator = "*";
    this.Origen = "";
    this.Destino = "";
    this.Equivale = "";
    this.tipoDestino = "";
    this.tipoOrigen = "";
    this.FgActConversion = true;
    this.Status = true;
    this.$nextTick(() => {
      this.$v.$reset();
    });
  }
  function selectOptions() {
      return [
        { 
          value: true, 
          label: this.$t('label.ACTIVO')
        },
        { 
          value: false, 
          label: this.$t('label.INACTIVO')
        }
      ];
    }
  function selectOperacion(){
    return[
      {
        value: "*",
        label: this.$t('label.multiplication') + " (*)",
      },
      {
        value: "/",
        label: "DIVISION (/)",
      },
    ];    
  }    
  function evaluaStatus() {
    if(this.FgActConversion !== this.Status){              
          this.$swal
            .fire(this.alertProperties({
              text: `${this.$t('label.changeStatusQuestion')+' '}?`,
            }))     
          .then((result) => {
          if (result.isConfirmed) {
              this.submit();
          }
        })
    }else{
        this.submit();
    }
          
  }
  function submit() {
    try {
      this.$v.$touch();
      if (this.$v.$invalid) {
        throw this.$t('label.errorsPleaseCheck');
      }
        if (this.$v.$invalid) return false;
        this.Loading = true;
        let res = [];
        let metodo = "";
        let ruta = "";
        let ConversionJson = [];
        //ASIGNACION DE VARIABLES PARA GUARDAR
        if (this.actualizar) {
          ConversionJson = {
            ConversionId: this.ConversionId,
            UnitMeasureFromId: this.Origen,
            UnitMeasureToId: this.Destino,
            Operator: this.Operator,
            Value: this.Equivale,
            Formula: `RESULTADO=VALOR${this.Operator}` + this.Equivale,
            Status: this.Status,
          };
          metodo = "PUT";
          ruta = "UnitMeasureConversion-update";
        } else {
          ConversionJson = {
            UnitMeasureFromId: this.Origen,
            UnitMeasureToId: this.Destino,
            Operator: this.Operator,
            Value: this.Equivale,
            Formula: `RESULTADO=VALOR${this.Operator}` + this.Equivale,
          };
          metodo = "POST";
          ruta = "UnitMeasureConversion-insert";
        }
        //ENVIANDO POST PARA GUARDAR O ACTUALIZAR
        this.$http
          .ejecutar(metodo, ruta, ConversionJson, {
            root: "ConversionJson",
          })
          .then((response) => {
            res = [...response.data.data];
            this.refreshComponent();
            this.$notify({
              group: "container",
              title: "¡Exito!",
              text: res[0].Response,
              type: "success",
            });
            this.Loading = false;
            this.$emit("child-refresh", true);
            this.AddModal = false;
          })
          .catch((err) => {
            this.$notify({
              group: "container",
              title: "¡Error!",
              text: err,
              type: "error",
            });
            this.Loading = false;
          });
    } catch (e) {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: e,
        type: "error",
      });
    }
  }

  function getDestinationOptions($event) {
    this.val = $event.target.value;
  }
 

  function getUnitMeasureTypeList() {
    this.itemsUnid = [];
    this.Loading = true;
    let listado = [];

    this.$http
      .get("TpUnitMeasure-list", { filter: "ALL" })
      .then((response) => {
      
        listado = [...response.data.data];
        this.itemsUnid = listado.map((listado) =>
          Object.assign({}, this.itemsUnid, {
            value: listado.TpUnitMeasureId,
            label: listado.TpUnitMeasureName,
          })
        );
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
      .then(() => {
        this.Loading = false;
      });
  }

//lista de unidades origen 
  function getUnitOriginList() {
    this.Loading = true;
    this.itemsOrigen = [];
    let listado = [];
    this.$http
      .get("UnitMeasureByTpUnitMeasureId", {
        TpUnitMeasureId: this.tipoOrigen,
      })
      .then((response) => {
        listado = [...response.data.data];
        this.itemsOrigen = listado.map((listado) =>
          Object.assign({}, this.itemsOrigen, {
            value: listado.UnitMeasureId,
            label: listado.UnitMeasureName,
          })
        );
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
      .then(() => {
        this.Loading = false;
      });
    }

//unidad destino
  function getUnitDestinationList() {
    this.itemsDestino = [];
    let listado = [];
    this.$http
      .get("UnitMeasureByTpUnitMeasureId", {
        TpUnitMeasureId: this.tipoDestino,
      })
      .then((response) => {
        listado = [...response.data.data];
        this.itemsDestino = listado.map((listado) =>
          Object.assign({}, this.itemsDestino, {
            value: listado.UnitMeasureId,
            label: listado.UnitMeasureName,
          })
        );
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
      .then(() => {
        this.Loading = false;
      });
    }



  async function checkInputs(value) {
    if(value){
      this.getUnitOriginList();
      this.getUnitDestinationList();
      this.$v.$touch();

    }
  }


  async function getTipoOrigen() {
      if (this.tipoOrigen) {
      // peso '83D0D706-AB41-4611-9711-2867A0F4A495' volumen '178F0914-5AE2-4E81-BC74-3BCAE6B6A612'   
        if(this.tipoOrigen == '83D0D706-AB41-4611-9711-2867A0F4A495' || this.tipoOrigen == '178F0914-5AE2-4E81-BC74-3BCAE6B6A612'){
          this.itemsUnid2 = [];
          this.itemsUnid2= [
            {
              value: '83D0D706-AB41-4611-9711-2867A0F4A495',
              label: "PESO",
            },
            {
              value: '178F0914-5AE2-4E81-BC74-3BCAE6B6A612',
              label: "VOLUMEN",
            },
          ],

          this.showDestinationType = true;
          this.tipoDestino = this.tipoOrigen;
          this.getUnitOriginList();
          this.getUnitDestinationList();

        }else if(this.tipoOrigen != '83D0D706-AB41-4611-9711-2867A0F4A495' && this.tipoOrigen != '178F0914-5AE2-4E81-BC74-3BCAE6B6A612'){
          this.showDestinationType = false;
          this.tipoDestino = this.tipoOrigen;
          this.itemsUnid2 = [];
          this.Origen = '';
          this.Destino = '';
          this.getUnitOriginList();
          this.getUnitDestinationList();

        }
      }
    }




export default {
  name: "add-modal",
  mixins: [General, ModalMixin],
  data,
  props: {
    modal: null,
    editModal: null,
  },
  methods: {
    getUnitMeasureTypeList,
    //check,
    getDestinationOptions,
    //comparar2,
    evaluaStatus,
    submit,
    refreshComponent,
    disabledInput,
    getUnitOriginList,
    getUnitDestinationList,
    checkInputs,
    getTipoOrigen

  },
  computed: {
    isDisabled,
    segundoSelect,
    selectOptions,
    selectOperacion
  },
  directives: UpperCase,
  validations: ConversionVal,
  watch: {
    modal: async function() {
      if (this.modal) {
        this.refreshComponent();
        this.getUnitMeasureTypeList();
        
        this.AddModal = true;
        if (this.modal == true) {
          this.tituloModal = this.$t('label.nueva')+' '+this.$t('label.conversionRule');
          this.actualizar = false;
        } else {
          this.actualizar = true;
          this.tituloModal = this.$t('label.edit')+' '+this.$t('label.conversionRule');
          this.ConversionId = this.modal.ConversionId;
          this.Operator = this.modal.Operador;
          this.Equivale = this.modal.Equivale;
          this.Formula = this.modal.Formula;
          this.tipoOrigen = this.modal.TpUnitMeasureId;
          this.tipoDestino = this.modal.TpUnitMeasureId;
          this.Origen = this.modal.UnitMeasureFromId;
          this.Destino = this.modal.UnitMeasureToId;
          this.Status = this.modal.FgActConversion;// == "ACTIVO" ? 1 : 0;
          this.FgActConversion = this.modal.FgActConversion;
          await this.checkInputs(this.actualizar);
        }
        this.$emit("cerrarModal");
      }
    },
    editModal: async function() {
      this.$v.$touch();

    },
    /*tipoOrigen: function() {
      if (this.tipoOrigen) { */
        //LONGITUD
        /*
        if (this.tipoOrigen == "D93F7A43-76D8-4128-BBDD-C9F194ED3659") {
          this.Valores = 100;
        } else {
          this.Valores = 1000;
        }*/
      /*  this.Loading = true;
        this.itemsOrigen = [];
        let listado = [];
        this.$http
          .get("UnitMeasureByTpUnitMeasureId", {
            TpUnitMeasureId: this.tipoOrigen,
          })
          .then((response) => {
            listado = [...response.data.data];
            this.itemsOrigen = listado.map((listado) =>
              Object.assign({}, this.itemsOrigen, {
                value: listado.UnitMeasureId,
                label: listado.UnitMeasureName,
              })
            );
          })
          .catch((err) => {
            this.$notify({
              group: "container",
              title: "¡Error!",
              text: err,
              type: "error",
            });
          })
          .then(() => {
            this.Loading = false;
          });
      }
    },
    tipoDestino: function() {
      if (this.tipoDestino != "") {
        if (
          this.tipoDestino == this.tipoOrigen ||
          this.tipoDestino == "178F0914-5AE2-4E81-BC74-3BCAE6B6A612"
        ) { */
          //LONGITUD
          /*
          if (this.tipoDestino == "D93F7A43-76D8-4128-BBDD-C9F194ED3659") {
            this.Valores = 100;
          } else {
            this.valores = 1000;
          }*/
    /*      if (
            this.tipoOrigen == "178F0914-5AE2-4E81-BC74-3BCAE6B6A612" ||
            this.tipoDestino == "178F0914-5AE2-4E81-BC74-3BCAE6B6A612"
          ) {
            this.labelEquivale = "DENSIDAD TON/M³";
          } else {
            this.labelEquivale = "EQUIVALE";
          }
          this.Loading = true;
          this.itemsDestino = [];
          let listado = [];
          this.$http
            .get("UnitMeasureByTpUnitMeasureId", {
              TpUnitMeasureId: this.tipoDestino,
            })
            .then((response) => {
              listado = [...response.data.data];
              this.itemsDestino = listado.map((listado) =>
                Object.assign({}, this.itemsDestino, {
                  value: listado.UnitMeasureId,
                  label: listado.UnitMeasureName,
                })
              );
            })
            .catch((err) => {
              this.$notify({
                group: "container",
                title: "¡Error!",
                text: err,
                type: "error",
              });
            })
            .then(() => {
              this.Loading = false;
            });
        } else {
          if (this.tipoDestino != "") {
            this.$swal
              .fire({
                text: `EL TIPO DE UNIDAD A CONVERTIR DEBE SER IGUAL AL TIPO DE UNIDAD DE ORIGEN
          EXCEPTO PESO/ VOLUMEN`,
                icon: "warning",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ACEPTAR",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.tipoDestino = "";
                  this.itemsDestino = [];
                }
              });
          }
        }
      }
    },*/

  },
};
</script>
